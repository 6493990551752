<template>
  <el-row class="movie-list">
    <el-col :md="12">
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <el-row>
              <span v-html="audioInfo.title" />
            </el-row>
          </div>
          <div class="text item">
            <el-row>
              <audio-player
                ref="audioPlayer"
                :is-loop="false"
                :show-prev-button="false"
                :show-next-button="false"
                :show-playback-rate="false"
                :audio-list="audioList.map(elm => elm.url)"
                :before-play="handleBeforePlay"
                theme-color="#87CEFA"
                @playing="onPlaying"
                @on-progress-move="onProgressMove"
                @ended="onEnd"
              />
            </el-row>
            <el-divider v-if="audioInfo.description !== undefined && audioInfo.description !== null" />
            <el-row>
              <span v-html="audioInfo.description" />
            </el-row>
            <el-divider />
            <el-row>
              发布于 <span v-html="audioInfo.publishAt" />
            </el-row>
            <el-divider />
            <el-row>
              <span>
                <span>
                  <i :class="collectedIcon" @click="collectItem" />
                </span>
              </span>
            </el-row>
          </div>
        </el-card>
      </el-row>
    </el-col>
    <el-col :md="6">
      <user-avatar-card :user-avatar="user" />
    </el-col>
  </el-row>
</template>

<script>
import UserAvatarCard from '@/components/card/UserAvatarCard'
import { getAudioInfo } from '@/api/audio'
import { getUserInfo } from '@/api/user'

export default {
  name: 'AudioPage',
  components: { UserAvatarCard },
  filters: {
    ellipsis(value) {
      if (!value) return ''
      const max = 20
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    }
  },
  data() {
    return {
      currentAudioName: '',
      audioInfo: null,
      audioList: [],
      user: null,
      collected: false,
      collectedIcon: 'el-icon-star-off'
    }
  },
  created() {
    const audioId = this.$route.params.audioId
    getAudioInfo(audioId).then(resp => {
      if (resp.code === 0) {
        this.audioInfo = resp.data
        this.audioList = [
          { name: this.audioInfo.title, url: this.audioInfo.audioUrl }
        ]
        document.title = '音频 - ' + this.audioInfo.title

        getUserInfo(this.audioInfo.publishBy).then(resp => {
          if (resp.code === 0) {
            this.user = resp.data
          }
        })
      }
    })
  },
  methods: {
    // 播放前做的事
    handleBeforePlay(next) {
      // 这里可以做一些事情...
      // this.audioList[this.$refs.audioPlayer.currentPlayIndex].name
      this.$refs.audioPlayer.$refs.audio.currentTime = this.audioInfo.currentTime
      // 开始播放
      next()
    },
    handlePlaySpecify() {
      this.$refs.audioPlayer.currentPlayIndex = 1
      this.$nextTick(() => {
        this.$refs.audioPlayer.play()
        this.title = this.audioList[
          this.$refs.audioPlayer.currentPlayIndex
        ].name
      })
    },
    onPlaying() {
      const jsonData = {}
      jsonData.mediaId = this.audioInfo.audioId
      jsonData.mediaType = 2
      jsonData.currentTime = this.$refs.audioPlayer.$refs.audio.currentTime
    },
    onProgressMove(event) {
    },
    onEnd(event) {
    },
    collectItem() {
      if (this.collected) {
        this.collected = false
        this.collectedIcon = 'el-icon-star-off'
      } else {
        this.collected = true
        this.collectedIcon = 'el-icon-star-on'
      }
      this.$message.info('暂未实现')
    }
  }
}
</script>

<style scoped>
.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}

/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>
