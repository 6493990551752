import { get, post } from '@/utils/request'

const userApi = {
  userInfoApi: '/api/user/info',
  updateProfileApi: '/api/user/profile/update',
  discoverUserApi: '/api/user/discover',
  followUserApi: '/api/user/relation/follow',
  unfollowUserApi: '/api/user/relation/unfollow',
  checkRelationApi: '/api/user/relation/check',
  userFollowerApi: '/api/user/relation/follower',
  userFollowingApi: '/api/user/relation/following',
  unreadMessageApi: '/api/user/message/unread',
  unreadMessagesApi: '/api/user/message/unread/list',
  setReadMessageApi: '/api/user/message/detail'
}

export function getUserInfo(userId) {
  return get(userApi.userInfoApi + '?userId=' + userId)
}

export function updateUserProfile(payload) {
  return post(userApi.updateProfileApi, payload)
}

export function getUsers() {
  return get(userApi.discoverUserApi)
}

// 关注用户
export function followUser(followingId) {
  return post(userApi.followUserApi + '/' + followingId)
}

// 取消关注用户
export function unfollowUser(followingId) {
  return post(userApi.unfollowUserApi + '/' + followingId)
}

export function checkRelation(userId) {
  return get(userApi.checkRelationApi + '/' + userId)
}

export function getUserFollower(userId) {
  return get(userApi.userFollowerApi + '/' + userId)
}

export function getUserFollowing(userId) {
  return get(userApi.userFollowingApi + '/' + userId)
}

export function getUnreadCount() {
  return get(userApi.unreadMessageApi)
}

export function getUnreadMessages(page) {
  return get(userApi.unreadMessagesApi + '?pageNumber=' + page)
}

export function getMessageDetail(messageId) {
  return get(userApi.setReadMessageApi + '/' + messageId)
}
