import {delete0, get, post} from '@/utils/request'

const audioApi = {
  audioPostApi: '/api/content/post/audio',
  updateAudioScopeApi: '/api/content/post/audio/update/scope',
  audioApi: '/api/content/audio',
  userAudioApi: '/api/content/audio/user',
}

export function addAudioPost(video) {
  return post(audioApi.audioPostApi, video)
}

export function updateAudioScope(audioScope) {
  return post(audioApi.updateAudioScopeApi, audioScope)
}

export function deleteAudioPost(audioId) {
  return delete0(audioApi.audioPostApi + '/' + audioId)
}

export function getAudioPosts(page) {
  return get(audioApi.audioPostApi + '?page=' + page)
}

export function getAudioPost(audioId) {
  return get(audioApi.audioPostApi + '/' + audioId)
}

export function getAudios(page) {
  return get(audioApi.audioApi + '?page=' + page)
}

export function getUserAudios(userId, page) {
  return get(audioApi.userAudioApi + '?userId=' + userId + '&page=' + page)
}

export function getAudioInfo(audioId) {
  return get(audioApi.audioApi + '/' + audioId)
}
