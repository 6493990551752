<template>
  <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-row>
          <el-col :md="4">
            <router-link target="_blank" :to="`/user/` + userAvatar.userId">
              <el-avatar>
                <el-image :src="userAvatar.avatarUrl" />
              </el-avatar>
            </router-link>
          </el-col>
          <el-col :md="16">
            <el-row>
              <span v-html="userAvatar.screenName" />
            </el-row>
            <el-row>
              <span>关注 {{ userAvatar.following }}</span>
              <span v-html="'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'" />
              <span>粉丝 {{ userAvatar.follower }}</span>
            </el-row>
            <el-row v-if="userAvatar.signature !== null">
              <span>{{ userAvatar.signature }}</span>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <div class="text item">
        <el-row>
          <el-col :md="18">
            <el-button
              v-if="userAvatar.followed"
              type="danger"
              size="mini"
              icon="el-icon-check"
              @click="unfollowUser(userAvatar.userId)"
            >
              <span>已关注</span>
            </el-button>
            <el-button
              v-else
              type="danger"
              size="mini"
              icon="el-icon-plus"
              @click="followUser(userAvatar.userId)"
            >
              <span>关注</span>
            </el-button>
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-message"
              @click="sendMessage(userAvatar.userId)"
            >
              <span>发消息</span>
            </el-button>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </el-row>
</template>

<script>
import { followUser, unfollowUser } from '@/api/user'

export default {
  name: 'UserAvatarCard',
  filters: {
    ellipsis(value) {
      if (!value) return ''
      const max = 20
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    }
  },
  props: {
    userAvatar: {
      type: Object,
      default: null
    },
    // 时间前的描述
    dateTit: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      followButton: {
        icon: 'el-icon-plus',
        text: '关注'
      }
    }
  },
  created() {
  },
  methods: {
    followUser(userId) {
      followUser(userId).then(resp => {
        if (resp.code === 0) {
          this.userAvatar.followed = true
        }
      })
    },
    unfollowUser(userId) {
      unfollowUser(userId).then(resp => {
        if (resp.code === 0) {
          this.userAvatar.followed = false
        }
      })
    },
    sendMessage(userId) {
      this.$message.info('暂未实现')
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>
