import { render, staticRenderFns } from "./UserAvatarCard.vue?vue&type=template&id=01b9d49f&scoped=true"
import script from "./UserAvatarCard.vue?vue&type=script&lang=js"
export * from "./UserAvatarCard.vue?vue&type=script&lang=js"
import style0 from "./UserAvatarCard.vue?vue&type=style&index=0&id=01b9d49f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01b9d49f",
  null
  
)

export default component.exports